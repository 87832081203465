import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import { mixins, theme } from '../../../styles'
import { SVGIcon } from '../SVGIcon'

export function ActionButton({
  pictoLeft,
  pictoRight,
  text,
  link,
  backgroundColor,
  color,
  borderColor,
  secondaryColor,
}: BlockTypes['action-button']['data']): JSX.Element {
  return (
    <>
      <div className="ActionButton__Wrapper flex justify-center align-center">
        <a
          className={['ActionButton', borderColor ? 'ActionButton--withBorder' : '']
            .filter(Boolean)
            .join(' ')}
          href={link}
        >
          <div
            className="ActionButton__Content flex justify-center items-center"
            style={{
              backgroundColor,
              color,
            }}
          >
            {pictoLeft ? (
              <SVGIcon
                name={pictoLeft}
                primaryColor={color}
                secondaryColor={secondaryColor}
                width="auto"
                height="20px"
              />
            ) : null}
            <span> {text} </span>
            {pictoRight ? (
              <SVGIcon
                name={pictoRight}
                primaryColor={color}
                secondaryColor={secondaryColor}
                width="auto"
                height="20px"
              />
            ) : null}
          </div>
        </a>
      </div>

      <style jsx>{`
        .ActionButton.ActionButton--withBorder {
          border: 2px solid ${borderColor};
        }
      `}</style>
      <style jsx global>{`
        @media (max-width: ${theme.breakpoints.md}px) {
          .Main__Body .ActionButton__Wrapper ~ .Hello {
            margin-top: 0;
          }
        }
      `}</style>
      <style jsx>{`
        .ActionButton__Wrapper {
          position: relative;
          box-sizing: border-box;
          width: 100%;
          padding: 30px ${theme.block.marginLR}px ${theme.block.marginBottom}px;
        }

        .ActionButton {
          width: 100%;
          max-width: 375px;
          border-radius: 64px;
        }

        .ActionButton.ActionButton--withBorder {
          padding: 4px;
        }

        .ActionButton__Content {
          padding: 12px;
          gap: 10px;
          border-radius: 64px;
        }

        .ActionButton span {
          font-size: 15px;
          font-weight: 700;
          line-height: 141%;
          padding-top: 3px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ActionButton__Wrapper {
            display: none;
          }
        }
      `}</style>
    </>
  )
}
